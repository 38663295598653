import * as React from "react"
import { Fragment, useEffect } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'

import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"


const Header = ({ siteTitle }) => {
  const [scrolled,setScrolled]=React.useState(false);
  const handleScroll=() => setScrolled(window.scrollY > 1 );
       

  useEffect(() => {
    window.addEventListener('scroll',handleScroll)
  })

  return(
  <Disclosure as="nav" className="bg-gray-800">
   
  {({ open }) => (
    <>
        <header className={"sageHeader relative ease-linear transition-all duration-200 z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-themeGray " + (scrolled?'md:scrolled' : "")}>
          <div className="container relative max-w-6xl px-4 mx-auto flex flex-wrap items-center justify-between">
            <Link to="/">
              <StaticImage
                  src="../images/SEIN-logo.png"
                  alt={siteTitle}
                  className="w-44 cursor-pointer"
                />
            </Link>
            <div className="hidden lg:block">
                <div className="flex flex-col md:flex-row items-center justify-end relative">
                    <nav className="my-1 md:py-1 md:border-gray-400	flex flex-wrap text-sm justify-end">
                        <Link
                            to="/"
                            activeClassName="text-emerald-800"
                            className="px-5 py-3 text-base font-semibold outline-none ease-linear transition-all duration-300 hover:text-emerald-800  cursor-pointer"
                        >
                            Home
                        </Link>
                        <Link
                            to="/about"
                            activeClassName="text-emerald-800"
                            className="px-5 py-3 text-base font-semibold outline-none ease-linear transition-all duration-300 hover:text-emerald-800  cursor-pointer"
                        >
                            About
                        </Link>
                            <Menu>
                            {({ open }) => (
                              <>
                                <div className="relative inline-block">
                                  <Menu.Button 
                                  className="inline-flex justify-center px-5 py-3 text-base font-semibold outline-none ease-linear transition-all duration-300 hover:text-emerald-800  cursor-pointer">
                                    <span>Products</span>
                                    <svg
                                      className="w-5 h-5 ml-2 -mr-1"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </Menu.Button>
                                  <Transition
                                    show={open}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                  <Menu.Items
                                    static
                                    className="absolute right-5 w-40 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
                                  >   
                                    <div className="py-2">
                                      <Menu.Item>
                                        {({ active }) => (
                                          <Link
                                            to="/products/sherpa"
                                            className={`${
                                              active
                                                ? "bg-emerald-400 text-white"
                                                : "text-gray-700"
                                            } flex justify-between w-full px-4 py-2 border-0 border-b border-solid border-gray-200 text-sm font-medium text-left`}
                                          >
                                            Sherpa
                                          </Link>
                                        )}
                                      </Menu.Item>
                                      <Menu.Item>
                                        {({ active }) => (
                                          <Link
                                            to="/products/rover"
                                            className={`${
                                              active
                                                ? "bg-emerald-400 text-white"
                                                : "text-gray-700"
                                            } flex justify-between w-full px-4 py-2 text-sm font-medium text-left`}
                                          >
                                            Rover
                                          </Link>
                                        )}
                                      </Menu.Item>
                                    </div>
                                  </Menu.Items>
                                  </Transition>
                                </div>
                              </>
                              )}
                            </Menu>
                            
                    </nav>
                    <a
                        href="https://sageterminal.com"
                        target="_blank"
                        className="bg-emerald-800 text-white active:bg-emerald-800 text-xs font-bold px-5 py-3 rounded-full shadow hover:bg-emerald-500 hover:shadow-lg outline-none ease-linear transition-all duration-300  lg:mr-1 lg:mb-0 ml-3 mb-3"
                        >
                        <span className=" inline-flex items-center">
                            SEIN Terminal
                            <svg
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            className="w-4 h-4 ml-1"
                            viewBox="0 0 24 24"
                            >
                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                            </svg>
                        </span>
                    </a>
                </div>
            </div>
            <div className="flex lg:hidden">
                <Disclosure.Button className="inline-flex items-center justify-center 
                p-2 rounded-md text-gray-400 hover:text-white hover:bg-emerald-400 
                focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                <span className="sr-only">Open main menu</span>
                {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                )}
                </Disclosure.Button>
            </div>
          </div>
        </header>

      <Disclosure.Panel className="lg:hidden absolute bg-white py-3 shadow-lg ring-1 ring-black ring-opacity-5 z-10 right-0 left-0 top-12 w-full">
              <Transition
                    show={open}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                <div className="px-2 pt-3 pb-3 space-y-1 text-center 
                  mt-2 origin-top-right">
                    <Link
                        to="/"
                    >
                      <Disclosure.Button
                          as="a"
                          className=" text-grey-900 block px-3 py-3 rounded-md text-base font-medium"
                        >
                        Home
                        </Disclosure.Button>
                    </Link>
                  
                   
                    <Link
                        to="/products"
                    >
                      <Disclosure.Button
                          as="a"
                          className="text-grey-900 block px-3 py-3 rounded-md text-base font-medium"
                        >
                        Products
                        </Disclosure.Button>
                    </Link>
                    <Link
                        to="/about"
                    >
                      <Disclosure.Button
                          as="a"
                          className=" text-grey-900 block px-3 py-3 rounded-md text-base font-medium"
                        >
                        About
                        </Disclosure.Button>
                    </Link>
                      <a
                            href="https://sageterminal.com"
                            target="_blank"
                            className="bg-emerald-400 text-white 
                            active:bg-emerald-400 
                            text-xs font-bold px-5 py-3
                            rounded-full shadow hover:bg-emerald-600 
                            hover:shadow-lg outline-none ease-linear 
                            transition-all duration-300  lg:mr-1 lg:mb-0 ml-3 mb-3 block"
                            >
                            <span className="block">
                                SEIN Terminal
                                <svg
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                className="w-4 h-4 ml-1 inline-block"
                                viewBox="0 0 24 24"
                                >
                                <path d="M5 12h14M12 5l7 7-7 7"></path>
                                </svg>
                            </span>
                        </a>
                </div>
            </Transition>
      </Disclosure.Panel>
    </>
  )}
 </Disclosure>
)
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
