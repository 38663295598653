import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import FacebookImg from "../images/facebook.png"
// import InstagramImg from "../images/instagram.png"
import LinkedinImg from "../images/linkedin.png"
import TwitterImg from "../images/twitter.png"

const companyInfo = [
  {
    name: "About Us",
    href: "/about",
  },
  {
    name: "Sherpa",
    href: "/products/sherpa",
  },
  {
    name: "Rover",
    href: "/products/rover",
  },
  {
    name: "Terms of Use",
    href: "/terms-of-use",
  },
  {
    name: "Privacy Policy",
    href: "/privacy-policy",
  },
  {
    name: "Careers",
    href: "/careers",
  },
]

const productFeatures = [
  {
    name: "Artificial Intelligence",
    href: "/products",
  },
  {
    name: "Real Time Notifications",
    href: "/products",
  },
  {
    name: "Modular Structure",
    href: "/products",
  },
  {
    name: "Cloud Based",
    href: "/products",
  },
  {
    name: "Data Repository",
    href: "/products",
  },
  {
    name: "Standardization",
    href: "/products",
  },
]

const Footer = ({ siteTitle }) => (
  <footer className="body-font w-full mt-4 tracking-wide relative border-t border-gray-100 ">
    <div className="hidden lg:block absolute inset-0 w-1/2 ml-auto bg-themeGray"></div>
    <div className="container px-4  max-w-6xl mx-auto items-center flex flex-wrap">
      <div className="flex flex-wrap w-full  max-w-2xl lg:max-w-full ">
        <div className="w-full w-full lg:w-1/2 pt-5 md:pt-20 px-4 mb-4 lg:mb-0">
          <a className="inline-block mx-auto lg:ml-0 text-3xl font-semibold leading-none">
            <Link to="/">
              <StaticImage
                src="../images/SEIN-Terminal.png"
                alt={siteTitle}
                className="w-44 cursor-pointer"
              />
            </Link>
          </a>
          <div className="mt-10 mb-5 pr-0 md:pr-10 ">
            <p className="mb-4 text-sm">
              Subscribe and stay fully connected with product.
            </p>
            <div className="w-full relative">
              <div className="flex w-full mb-3 lg:mb-0 px-4 bg-gray-100 rounded relative">
                <input
                  className="w-full py-3 pl-3 h-[60px] text-sm text-gray-500 bg-gray-100 outline-none"
                  type="text"
                  placeholder="Enter your email address"
                />
              </div>
              <button
                className="bg-emerald-800 text-white active:bg-emerald-400 text-base
                                     font-medium px-6 py-3 h-[44px] rounded shadow hover:bg-emerald-600 hover:shadow-lg outline-none ease-linear transition-all duration-300 
                                     relative md:absolute top-2 right-0 md:right-3 inline  w-full md:w-32 "
                type="submit"
              >
                Subscribe
              </button>
            </div>
          </div>
          <div>
            <p className="mb-2 text-sm text-gray-600 underline decoration-1">
              info@sein.tech
            </p>
            <p className="mb-2 text-sm text-gray-600 decoration-1">
              +1(888)-240-3832
            </p>
            <p className="mb-2 text-sm text-gray-600 ">
              Atlanta | Baltimore | D.C.
            </p>
          </div>
          <div className="w-full lg:block">
            <div className="-mx-2 mt-10">
              <a
                className="inline-block px-2 hover:opacity-80"
                href="facebook.com"
                target="_blank"
              >
                <img className="" src={FacebookImg} alt="facebook-icon" />
              </a>
              {/* <a
                className="inline-block px-2 hover:opacity-80"
                href="instagram.com"
                target="_blank"
              >
                <img className="" src={InstagramImg} alt="instagram-icon" />
              </a> */}
               <a
                className="inline-block px-2 hover:opacity-80"
                href="linkedin.com"
                target="_blank"
              >
                <img className="" src={LinkedinImg} alt="Linkedin-icon" />
              </a>
              <a
                className="inline-block px-2 hover:opacity-80"
                href="twitter.com"
                target="_blank"
              >
                <img className="" src={TwitterImg} alt="twitter-icon" />
              </a>
            </div>
          </div>
        </div>
        <div className="relative w-full lg:w-1/2 px-4 pt-4 mb-4 md:pt-20 md:pb-12 lg:pl-12">
          <div className="flex flex-wrap w-full  max-w-2xl lg:max-w-full ">
            <div className="w-1/2">
              <h2 className="text-lg font-medium mb-5 text-emerald-800">
                Company Info
              </h2>
              <nav className="leading-relaxed list-none mb-3 text-sm">
                {companyInfo.map(cinfo => (
                  <li className="link-hover my-3" key={cinfo.name}>
                    <Link to={cinfo.href}>{cinfo.name}</Link>
                  </li>
                ))}
              </nav>
            </div>
            <div className="w-1/2">
              <h2 className="text-lg font-medium mb-5 text-emerald-800">
                Product Features
              </h2>
              <nav className="leading-relaxed  list-none mb-3 text-sm">
                {productFeatures.map(pfeatures => (
                  <li className="link-hover my-3" key={pfeatures.name}>
                    <Link to={pfeatures.href}>{pfeatures.name}</Link>
                  </li>
                ))}
              </nav>
            </div>
          </div>
          <div className="flex flex-row flex-wrap mt-10">
            <p className="text-sm text-gay-400">
              Copyright © 2023 All rights reserved | SEIN Tech Partners
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
)
Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}
export default Footer
